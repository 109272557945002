import React from 'react'
import "./SearchBar.scss"

function VerificationMail() {
  return (
    <div className="verificationmail">
        <h1>Verification Mail Sent Successfully !</h1>
        <p>Verification Link has been successfully sent on your email, click on it to verify your account on bankster</p>
        <p></p>
    </div>
  )
}

export default VerificationMail