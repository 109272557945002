import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import "./Pages.scss"

function FAQ() {
  return (
    <div>
    <Header />
        <div className="page-section">
        <h1>FAQ Us</h1>
        <h2>Does it cost anything to me?</h2>
        <p>Candidate registration on BanksterIndia is free of cost.</p>

        <h2>How do I register on Banksterindia.com?</h2>
        <p>It's a simple 2-step process. Just log on to www.banksterindia and click on the signup button on the right side of the page.</p>
        <p>Step 1: Fill in your details</p>
        <p>Step 2: Activate your registration by clicking on the link sent to you in the welcome email.</p>


        <h2>Do I need a valid email id for registration?</h2>
        <p>Yes, you need a valid email id to register on BanksterIndia. The same email id will be used to communicate with you in the future. We value your privacy, and we hate spam as much as you do. We are not going to spam or sell your email address to any third party. We still need your email account to be able to provide you with your Job related information or for any other career related notifications you may choose.</p>

        <h2>Employment Information</h2>
        <p>Why do I need to enter my Employment Information?
        We need your employment information to know the details about your past experience. This will help us match your profile with the right opportunities.</p>

        <h2>How do I enter my Jobs History?</h2>
        <p>Candidate needs to enter the Jobs History information on page 2 of the registration process. Follow these steps to enter the information:</p>
        <p>1) You need to fill the Job Title and the Company Name.</p>
        <p>2) You need to select Industry of the Company from the list generated. You can use "Know More" link to find out what is the Industry of your Company.</p>
        <p>3) Then select the Functional Area of the Job from the list generated. You can use "Know More" link to find out what is Functional Area.</p>
        <p>4) Select the no. of years & months you have been in this job</p>

        <h2>Can I enter multiple jobs?</h2>
        <p>Yes, you can enter multiple job by login to your Shine account, clicking on My Profile Experience in left Bar.,. Click on "Add Previous Job(s) details to get better matched jobs" button to enter multiple jobs.</p>

        <h2>Can I skip the current salary field?</h2>
        <p>We value your privacy. Current salary is a mandatory field as we need this information to help you understand your current position vis-a-vis peers, and estimate the average annual salary for your profile in the market with our internationally proven salary benchmarking tool. Moreover, Recruiters use this information to select candidates.</p>

        <h2>How do I add my education qualifications?</h2>
        <p>We need your education information to know the details about your Qualifications. This will help us match your profile with the right opportunities.</p>

        <p>1) Select your Latest Qualification Level from the list generated.</p>
        <p>2) Select your Education Specialization from the list generated.</p>
        <p>3) Fill your Institute Name select your Course Type</p>

        <h2>How Can I enter multiple education qualifications?</h2>
        <p>You can enter multiple Education Qualifications by login to your BanksterIndia account, </p>
        <h2>Job Preference</h2>
        <p>What is Desired Job Details?</p>
        <p>Desired Job Details gives you an opportunity to provide your preferred location and preferred specializations in which you are looking for a job which might differ from your current one. This will make sure that you receive only those matches which match your profile as well as the preferences.</p>

        <p><b>How do I enter my Location/City Preference?</b></p>
        <p>You can enter your Preferred City or Location by login to your BanksterIndia account</p>

        <p><b>How do I enter my preferred Functional Area and Specialization?</b></p>
        <p>You can enter your Preferred functional area by login to your BanksterIndia account</p>

        <p><b>How do I enter my preferred Industry?</b></p>
        <p>You can enter your Preferred industry by login to your BanksterIndia account</p>

        <p><b>How can I remove these preferences?</b></p>
        <p>If you wish remove these selected preferences, just click on the cross visible on the right hand side of each selected item in the list.</p>

        <p><b>Why do I need to click on the link sent to me in the verification email? Is there any other way of activating my account?</b></p>
        <p>Verification of user's email id is required to make sure that no one other than the user himself/herself is using his/her email id to register on Shine.com. Clicking on the link in the verification mail activates your account on Shine.com.
        No, there is no other way of activating your account, and thus we suggest you use a valid email id to register on BanksterIndia.</p>

        <p><b>How can I update or edit my profile in the future?</b></p>
        <p>To update or edit your profile on Shine.com, you will have to log in and click on edit profile link in "My Profile" section. It is important that you keep you profile up-to-date to ensure that you receive relevant matched positions. You can also update/edit your profile by clicking on the links available in the Top Window of your account.</p>

        <p><b>Who all can view my profile?</b></p>
        <p>You can set your profile status under the Account Details by clicking on the "General" tab of "My Profile". You have the following two options for your profile status:
<br />1)You can keep your profile visible: In this case, all recruiters will be able to see your complete profile.
<br />2)You can keep your profile anonymous: In this case, your name, email, contact numbers, address, resume, photo and information about companies you have worked for, will be hidden from the recruiter. Recruiter will be able to see other details of your profile.
Once you apply for a job, your complete profile will be visible to the employer who posted that job.
At the time of registration your profile's default status will be anonymous.
</p>

        <h2>Explore Jobs</h2>
        <p><b>How can I search jobs?</b></p>
        <p>As our system works for you and find you the matched positions for your skills and preferences, you can also use the search functions to search for jobs. To search jobs, use the search bar at the top.
If the search jobs throw lot of jobs, you can precise your search by using the advanced search option.
</p>

        <p><b>How do I apply for the jobs which I find in the search?</b></p>
        <p>To apply for a job from the search result, you need to first log in and then click on the apply button given with the job posting. Clicking on this button will take you to the Select Resume page. Select your resume or upload a New resume and click on the button "Apply" to apply for that job.</p>

        <p><b>There are a lot of results to my job search. Can I filter the results?</b></p>
        <p>Yes, you can filter the job results. Filter options are available in the left navigation panel right next to the search results. You can filter results by Industry, Experience, Salary, Functional area and Location.</p>

        <h2>Career Info</h2>

        <p><b>What is Career Info Section?</b></p>
        <p>Career Info section aims at providing the relevant industry and company information, updated industry and company specific news, featured articles by industry and in house experts. This will provide a job-seeker with enough information about an Industry/ Company/ Job role to be able to take career related decisions like whether to apply for a job in a certain industry/ company, to accept an offer, etc.</p>

        <p><b>How can I access information on specific industries?</b></p>
        <p>To read more about specific industries, click on the "Career Info." in the top navigation</p>

        <p><b>How can I access Company's Profile?</b></p>
        <p>To access a specific company profile, just type in the company name in the box "Search" and press the Go button.</p>

        <p><b>How can I access industry specific news?</b></p>
        <p>To access industry specific news, click on the industry in the Industry News from the top navigation panel to reach the industry page.</p>

        <p><b>How can I be a part of the discussion on particular industry/company?</b></p>
        <p><b>To be a part of the discussion on a particular industry/company you can post your comments on the articles on industries and companies. Click on the comments link under the heading of the article to add your comment. How can I access the artic</b>les?
You can access articles on the following topics: Resumes, Interview Preparation, Salary and Appraisal, Leadership and Work Place Tips by clicking on the relevant link in the top navigation panel.
</p>


        <h2>Log in / Home</h2>
        <p><b>How do I log in?</b></p>
        <p>Reach the candidate log in by entering your log in and password in the site header or by clicking on Login in the top navigation bar of the homepage header and enter your username (email id) and password.</p>


        <p><b>I have created my profile but not able to log in?</b></p>
        <p>You need to check your username and password if you are not able to log in to your account. Please visit your email id and look for the welcome mail received from Team BanksterIndia</p>

        <p><b>I don't remember my password. How do I retrieve my password?</b></p>
        <p>If you are not able to trace the welcome mail received from Team Shine and you don't remember your password, click on the "Forgot Password" link below the log in box. It will prompt you to enter your username (email id). A new password will be sent to you on your email id.</p>
        
        



        </div>
    <Footer />
    </div>
  )
}

export default FAQ