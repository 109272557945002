import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import "./Pages.scss"

function PrivacyPolicy() {
  return (
    <div>
    <Header />
        <div className="page-section">
        <h1>Privacy Policy</h1>
        <p>
        Your privacy is an important part of our relationship with you. Protecting your privacy is only part of our mission to provide a secure web environment. When using our site, including our services, your information will remain strictly confidential. Contributions made on our blog or on our forum are open to public view; so please do not post any personal information in your dealings with others. We accept no liability for those actions because it is your sole responsibility to adequate and safe post content on our site. We will not share, rent or share your information with third parties. 
        </p>
        <p>
        When you visit our site, we collect technical information about your computer and how you access our website and analyze this information such as Internet Protocol (IP) address of your computer, the operating system used by your computer, the browser (eg, Chrome, Firefox, Internet Explorer or other) your computer uses, the name of your Internet service provider (ISP), the Uniform Resource Locator (URL) of the website from which you come and the URL to which you go next and certain operating metrics such as the number of times you use our website. 
        </p>

        <h2>HOW WE USE COLLECTED INFORMATION?</h2>
        <p><b>BanksterIndia</b> collects and uses Users personal information for the following purposes:</p>
        <ul>
            <li><b>To personalize user experience:</b> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
            <li><b>To improve our Site:</b> We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
            <li><b>To improve customer service:</b> Your information helps us to more effectively respond to your customer service requests and support needs.</li>
            <li><b>To process transactions:</b> We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service by our registered and premium members.</li>
            <li><b>To administer content, promotion, survey or other Site feature:</b> To send Users information they agreed to receive about topics we think will be of interest to them.</li>
            <li><b>To send periodic emails:</b> 
            The email address Users provide for registering with WorkIndia.in, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, offers, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, they can submit unsubscribe request. We've included unsubscribe option at the bottom of each email.
            </li>
        </ul>
        <h2>HOW WE PROTECT YOUR INFORMATION?</h2>
        <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. We have physical, electronic, and procedural safeguards that comply with the laws prevalent in India to protect personal information about you. We seek to ensure compliance with the requirements of the Information Technology Act, 2000 as amended and rules made thereunder to ensure the protection and preservation of your privacy.</p>
        <p><b>UNLESS YOU REQUEST THAT WE DELETE CERTAIN INFORMATION, WE RETAIN THE INFORMATION WE COLLECT FOR AT LEAST 5 YEARS AND MAY RETAIN THE INFORMATION FOR AS LONG AS NEEDED FOR OUR BUSINESS AND LEGAL PURPOSES.</b></p>
        <h2>SHARING YOUR PERSONAL INFORMATION</h2>
        <p>We do not sell, trade, or rent personal identification information about you with other persons (save with your consent) or non-affiliated companies except to provide services you have requested, when we have your permission or under the following circumstances.</p>
        <ul>
            <li>We provide the information to trusted partners who work on behalf of or with us under confidentiality agreements. These companies may use your personal information to help us communicate with you about our offers and our marketing partners. However, these companies do not have any independent right to share this information.</li>
            <li>We transfer information about you if we are acquired by or merged with another company. In this event, we will notify you before information about you is transferred and becomes subject to a different privacy policy.</li>
        </ul>
        <p>We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
        <p>We share sensitive personal information to any third party without obtaining the prior consent of the User</p>
        <ul>
            <li>When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences.</li>
            <li>When we respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
            <li>When we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law.</li>
        </ul>
        <p>These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing the Privacy Policy and for complying with the applicable laws and regulations.</p>

        <h2>THIRD PARTY WEBSITES</h2>
        <p>Users may find advertising or other content on our Site that link to the sites and services of our partners, institutes, advertisers, sponsors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.</p>

        <h2>SECURITY</h2>
        <p>
        <b>BanksterIndia</b> takes industry standard protocols and technology to protect registered user information. <b>BanksterIndia</b> also protects registered user information offline. All registered user information is restricted within our offices. Servers used to store personally identifiable information are housed in a secure, supervised environment. In addition, only our employees who need specific information to perform a task are granted access to personally identifiable information. <b>BanksterIndia</b> takes commercially reasonable steps to help protect and secure the information it collects and stores about our Users.
        </p>

        <p>All access to the Site is encrypted using industry-standard transport layer security technology (TLS). When you enter sensitive information (such as tax identification number), we encrypt the transmission of that information using secure socket layer technology (SSL). We also use HTTP strict transport security to add an additional layer of protection for our Users. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Thus, while we strive to protect your personal data, we cannot ensure and do not warrant the security of any information you transmit to us. We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personal information (including sensitive personal information). Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you finish using a shared computer. Click here for more information on how to sign off.</p>

        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>We reserve the right, without notice, to change, modify, add or remove portions of our Privacy Policy at any time and from time to time. These changes will be posted publicly on our website. When you visit our website, you accept all the terms of our privacy policy. Your continued use of this website constitutes your continued agreement to these terms. If you do not agree with the terms of our privacy policy, you should cease using our website.</p>

        </div>
    <Footer />
    </div>
  )
}

export default PrivacyPolicy