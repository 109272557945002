import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import "./Pages.scss"

function Terms() {
  return (
    <div>
    <Header />
        <div className="page-section">
        <h1>Terms</h1>
        <h2>Definitions</h2>
        <p>Each of the terms mentioned below have in these Conditions of Sale Bankster India Service (hereinafter the "Conditions") the following meanings:</p>
        <p>1.	Announcement: refers to all the elements and data (visual, textual, sound, photographs, drawings), presented by an Advertiser editorial under his sole responsibility, in order to buy, rent or sell a product or service and broadcast on the Website and Mobile Site.</p>
        <p>2.	Advertiser: means any natural or legal person, a major, established in France, holds an account and having submitted an announcement, from it, on the Website. Any Advertiser must be connected to the Personal Account for deposit and or manage its ads. Ad first deposit automatically entails the establishment of a Personal Account to the Advertiser.</p>
        <p>3.	Personal Account: refers to the free space than any Advertiser must create and which it should connect from the Website to disseminate, manage and view its ads.</p>
        <p>4.	Bankster India: means the company that publishes and operates the Website and Mobile Site [Your Company], registered at the Trade and Companies Register of Cottoned under the number [Your Company Registration Number] whose registered office is at [Your Company Address].</p>
        <p>5.	Customer Service: Job Class means the department to which the Advertiser may obtain further information. This service can be contacted via email by clicking the link on the Website and Mobile Site.</p>
        <p>6.	Bankster India Service: Bankster India means the services made available to Users and Advertisers on the Website and Mobile Site.</p>
        <p>7.	Website : means the website operated by Bankster India accessed mainly from the URL BanksterIndia.com Bankster India and allowing Users and Advertisers to access the Service via internet Bankster India.</p>
        <p>8.	Mobile Site : is the mobile site operated by Bankster India accessible from the URL BanksterIndia.com and allowing Users and Advertisers to access via their mobile phone service [Your Site Name].</p>
        <p>9.	User: any visitor with access to Bankster India Service via the Website and Mobile Site and Consultant Service Bankster India accessible from different media.</p>
        <h2>Subject</h2>
        <p>These Terms and Conditions Of Use establish the contractual conditions applicable to any subscription by an Advertiser connected to its Personal Account from the Website and Mobile Site.</p>

        <h2>Acceptance</h2>
        <p>Any use of the website by an Advertiser is full acceptance of the current Terms.</p>

        <h2>Responsibility</h2>
        <p>Responsibility for Bankster India cannot be held liable for non-performance or improper performance of due control, either because of the Advertiser, or a case of major force.</p>

        <h2>Miscellaneous</h2>
        <p>If part of the Terms should be illegal, invalid or unenforceable for any reason whatsoever, the provisions in question would be deemed unwritten, without questioning the validity of the remaining provisions will continue to apply between Advertisers and Bankster India.</p>

        <p>Any complaints should be addressed to Customer Service Bankster India.</p>
        <p><b>
        You must take the time to read and understand the Terms and Conditions before registering for our services. By registering, you accept that you are entering into a contract with us based on these Terms and Conditions.
Visitors to WorkIndia.in who do not register to become a Member (Employer or Employee) similarly affirm that they are bound by these Terms and Conditions each time they access the WorkIndia.in website.
If you do not accept the Terms and Conditions stated below, please refrain from using WorkIndia.in
</b></p>

        <p>1.	The use of the Website by an Employer or Employee shall be deemed acceptance of and agreement to these terms only.</p>
        <p>2.	The Website has been established to allow Employer to contact Employees and to view their profiles detailing an Employee's experience. We do not issue any experience certificate to our registered members.</p>
        <p>3.	Any profile created showing political or illegal material would not be accepted under any circumstances.</p>
        <p>4.	BanksterIndia will take all reasonable precautions to keep the details of Employers and Employees secure but will not be liable for unauthorized access to the information provided by any party whatsoever.</p>
        <p>5.	The Members warrant that their e-mail and other contact addresses are valid and up to date when using the Website.</p>
        <p>6.	Members agree not to impersonate any other person or entity or to use a false name or a name that they have no authority to use.</p>
        <p>7.	Members acknowledge that WorkIndia.in is not liable for any form of loss or damage that may be suffered by a Member through the use of the Website including loss of data or information or any kind of financial or physical loss or damage.</p>
        <p>8.	BanksterIndia privacy policy forms part of these Terms and Conditions, and by agreeing to these Terms and Conditions, you also give your consent to the manner in which we may handle your personal data as detailed in that policy.</p>
        <p>9.	The management reserves the right to modify the Terms and Conditions at any time without any prior notification.</p>
        <p>10.	These Terms will be subject to Indian Law and the jurisdiction of Indian Courts.</p>
        <p>11.	We do not cater Placement Agencies and consultancies. Any payments made by Placement Agencies/ Consultancies will not be refunded under any situation.</p>
        <p>12.	BanksterIndia is not responsible if any candidate has committed crime/illegal activity at employer's premises. Background verification of candidates who are/will be hired is a responsibility of respective recruiter/recruiter's company.</p>


        </div>
    <Footer />
    </div>
  )
}

export default Terms